import $http from '@/utitls/request'
/**
 * 获取设备类型填充信息
 */
export const GetDeviceTypeFillList = (data) => {
    return $http.get('/api/DeviceType/GetDeviceTypeFillList', { params: data })
}
/**
 * 分页获取设备类型信息
 */
export const GetDeviceTypePageList = (data) => {
    return $http.get('/api/DeviceType/GetDeviceTypePageList', { params: data })
}
/**
 * 新增
 */
export const AddDeviceType = (data) => {
    return $http.post('/api/DeviceType/AddDeviceType', data)
}
/**
 * 修改
 */
export const EditDeviceType = (data) => {
    return $http.put('/api/DeviceType/EditDeviceType', data)
}
/**
 * 删除
 */
export const DeleteDeviceType = (data) => {
    return $http.delete('/api/DeviceType/DeleteDeviceType', { params: data })
}
<template>
  <div class="content">
    <TitleInfo title1="数据监控" title2="数据趋势"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>设备类型：</div>
          <el-select
            v-model="deviceTypeID"
            placeholder="Select"
            @change="HandleChangeDeviceType"
          >
            <el-option
              v-for="item in typeInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">设备名称：</div>
          <el-select v-model="queryForm.deviceID" placeholder="Select">
            <el-option
              v-for="item in deviceInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">设备参数：</div>
          <el-select
            v-model="queryForm.parameID"
            placeholder="Select"
            multiple
            collapse-tags
            collapse-tags-tooltip
          >
            <el-option
              v-for="item in parameInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">存储时间：</div>
          <el-date-picker
            v-model="dateRang"
            type="datetimerange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :shortcuts="shortcuts"
            format="YYYY-MM-DD HH:mm:ss"
            :clearable="false"
            style="width: 350px"
          >
          </el-date-picker>
          <el-button
            style="margin-left: 10px"
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >生成</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
        </div>
      </div>
      <div class="tableInfo" id="chart1"></div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { Search, Refresh, Edit, Delete } from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetHistoryDataList } from '@/api/DataRecord'
import { GetDeviceTypeFillList } from '@/api/DeviceType'
import { GetDeviceParameList } from '@/api/DeviceParame'
import { GetDeviceFillListByTypeID } from '@/api/Device'
import * as echarts from 'echarts'
import moment from 'moment'
const queryForm = ref({
  deviceID: '',
  dtStart: new Date(),
  dtEnd: new Date().getTime() - 3600 * 1000 * 24 * 7,
  parameID: []
})
const deviceTypeID = ref('')
const typeInfo = ref([])
const deviceInfo = ref([])
const parameInfo = ref([])
const loading = ref(true)
const loadingText = ref('数据加载中...')
//起止日期
const shortcuts = ref([
  {
    text: '最近一周',
    value: () => {
      return [moment().subtract(7, 'days').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近一个月',
    value: () => {
      return [moment().subtract(1, 'months').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近三个月',
    value: () => {
      return [moment().subtract(3, 'months').calendar(), moment().endOf('day')]
    }
  }
])
const dateRang = ref([moment().startOf('day'), moment().endOf('day')])
const tableData = reactive([])
onMounted(() => {
  GetDeviceTypeFillList()
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            typeInfo.value.push({
              ID: item.ID,
              Name: item.Name
            })
          })
          deviceTypeID.value = res.Data[0].ID
          initParameList()
          initDeviceList()
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })

  const command_edom1 = document.getElementById('chart1')
  command_edom1.removeAttribute('_echarts_instance_')
  chart1 = myEcharts.init(command_edom1, 'purple-passion')
  window.onresize = function () {
    if (chart1 != null) chart1.resize()
  }
})
const initDeviceList = () => {
  deviceInfo.value.length = 0
  GetDeviceFillListByTypeID({ deviceTypeID: deviceTypeID.value })
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            deviceInfo.value.push({
              ID: item.ID,
              Name: item.Name
            })
          })
          queryForm.value.deviceID = res.Data[0].ID
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
}
const initParameList = () => {
  parameInfo.value.length = 0
  queryForm.value.parameID.length = 0
  GetDeviceParameList({ deviceTypeID: deviceTypeID.value })
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            if (item.ToHistory)
              parameInfo.value.push({
                ID: item.ID,
                Name: item.Name
              })
          })
          if (parameInfo.value.length > 0)
            queryForm.value.parameID.push(parameInfo.value[0].ID)
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
}
const initGetList = async () => {
  if (queryForm.value.parameID.length == 0) {
    ElMessage({
      showClose: true,
      message: '设备参数不能为空',
      type: 'error'
    })
  } else {
    loadingText.value = '数据加载中...'
    loading.value = true
    tableData.length = 0
    queryForm.value.dtStart = moment(dateRang.value[0]).format(
      'yyyy-MM-D HH:mm:ss'
    )
    queryForm.value.dtEnd = moment(dateRang.value[1]).format(
      'yyyy-MM-D HH:mm:ss'
    )
    GetHistoryDataList(queryForm.value)
      .then((res) => {
        loading.value = false
        if (res) {
          tableData.length = 0
          if (res.Data.length > 0) {
            res.Data.forEach((element) => {
              tableData.push(JSON.parse(element))
            })
          }
        } else {
          tableData.length = 0
        }
      })
      .catch((err) => {
        loading.value = false
        ElMessage({
          showClose: true,
          message: `${err}`,
          type: 'error'
        })
        console.log(err)
        tableData.length = 0
      })
  }
}
const resetInfo = () => {
  queryForm.value = {
    userID: '',
    dtStart: new Date(),
    dtEnd: new Date().getTime() - 3600 * 1000 * 24 * 7,
    parameID: []
  }
}
const HandleChangeDeviceType = (e) => {
  loadingText.value = '数据加载中...'
  loading.value = true
  queryForm.value.deviceID = ''
  tableData.length = 0
  initParameList()
  initDeviceList()
}
let myEcharts = echarts
let chart1
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>

import $http from '@/utitls/request'
/**
 * 获取客户填充信息
 */
export const GetCustomeFillList = (data) => {
    return $http.get('/api/Device/GetCustomeFillList', { params: data })
}
/**
 * 获取设备填充信息
 */
export const GetDeviceFillList = (data) => {
    return $http.get('/api/Device/GetDeviceFillList', { params: data })
}
/**
 * 获取指定设备类型的设备填充信息
 */
export const GetDeviceFillListByTypeID = (data) => {
    return $http.get('/api/Device/GetDeviceFillListByTypeID', { params: data })
}
/**
 * 分页获取设备信息
 */
export const GetDevicePageList = (data) => {
    return $http.get('/api/Device/GetDevicePageList', { params: data })
}
/**
 * 分页获取设备场景信息
 */
export const GetDeviceScenePageList = (data) => {
    return $http.get('/api/Device/GetDeviceScenePageList', { params: data })
}
/**
 * 分页获取设备信息
 */
export const GetDeviceList = (data) => {
    return $http.get('/api/Device/GetDeviceList', { params: data })
}
/**
 * 新增
 */
export const AddDevice = (data) => {
    return $http.post('/api/Device/AddDevice', data)
}
/**
 * 修改
 */
export const EditDevice = (data) => {
    return $http.put('/api/Device/EditDevice', data)
}
/**
 * 删除
 */
export const DeleteDevice = (data) => {
    return $http.delete('/api/Device/DeleteDevice', { params: data })
}
/**
 * 设置设备权限
 */
export const SetDevicePermission = (data) => {
    return $http.post('/api/Device/SetDevicePermission', data)
}
import $http from '@/utitls/request'
/**
 * 获取指定设备类型参数信息
 */
export const GetDeviceParameList = (data) => {
    return $http.get('/api/DeviceParame/GetDeviceParameList', { params: data })
}
/**
 * 获取指定设备参数信息
 */
export const GetDeviceParameListByDeviceID = (data) => {
    return $http.get('/api/DeviceParame/GetDeviceParameListByDeviceID', { params: data })
}
/**
 * 分页获取设备参数信息
 */
export const GetDeviceParamePageList = (data) => {
    return $http.get('/api/DeviceParame/GetDeviceParamePageList', { params: data })
}
/**
 * 新增
 */
export const AddDeviceParame = (data) => {
    return $http.post('/api/DeviceParame/AddDeviceParame', data)
}
/**
 * 修改
 */
export const EditDeviceParame = (data) => {
    return $http.put('/api/DeviceParame/EditDeviceParame', data)
}
/**
 * 删除
 */
export const DeleteDeviceParame = (data) => {
    return $http.delete('/api/DeviceParame/DeleteDeviceParame', { params: data })
}
/**
 * 下载参数模板
 */
export const GetParameModel = (data) => {
    return $http.get('/api/DeviceParame/GetParameModel', { params: data, responseType: 'blob' })
}
/**
 * 下载指定设备类型的参数模板
 */
export const DownloadDeviceParames = (data) => {
    return $http.get('/api/DeviceParame/DownloadDeviceParames', { params: data, responseType: 'blob' })
}
/**
 * 上传设备参数
 */
export const UploadDeviceParames = (data) => {
    return $http.post('/api/DeviceParame/UploadDeviceParames', data, { headers: { 'Content-Type': 'multipart/form-data' } })
}